export class Tag {
  id = null
  userId = null
  name = null
  color = '#000000'
  createdAt = null
  updatedAt = null

  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (!data) return
    this.id = data.id
    this.userId = data.user_id
    this.name = data.name
    this.color = data.color
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }
}
