import Tags from '@/components/Rcs/Tags/Tags.vue'
import InactivityDays from '@/components/Rcs/InactivityDays/InactivityDays.vue'
import WelcomeMessage from '@/components/Rcs/WelcomeMessage/WelcomeMessage.vue'
import CloseReason from '@/components/Rcs/CloseReason/CloseReason.vue'
import { UserInboxAuthomaticMessages } from '@/models/whatsapp/UserInboxAuthomaticMessages.js'
import EmojiPickerInput from '@/components/EmojiPickerInput/EmojiPickerInput.vue'
import TextFormatting from '@/components/TextFormatting/TextFormatting.vue'
import AvailableTime from '@/components/Rcs/AvailableTime/AvailableTime.vue'
import { AvailableTime as AvailableTimeModel } from '@/models/voice/AvailableTime'
import { UserInboxAllowedHours } from '@/models/channel-integration/UserInboxAllowedHours.js'
import InboxConfigService from '@/services/rcs/inbox-config.service.js'
import { UserInboxQuickResponses } from '@/models/whatsapp/UserInboxQuickResponses.js'
import TriggeredMessage from '@/components/Rcs/TriggeredMessage/TriggeredMessage.vue'
import EventBus from '@/util/EventBus'

export default {
    name: 'InboxConfig',
    components: {
        Tags,
        WelcomeMessage,
        InactivityDays,
        CloseReason,
        TextFormatting,
        EmojiPickerInput,
        AvailableTime,
        TriggeredMessage,
    },
    props: { },
    data () {
        return {
            selectedComponent: null,
            ready: false,
            userInboxAllowedHours: new UserInboxAllowedHours(),
            userInboxWelcomeMessage: new UserInboxAuthomaticMessages(),
            userInboxAbsentMessage: new UserInboxAuthomaticMessages(),
            availableTimes: {
                times: [],
            },
            itemsPerPage: 5,
            pageCount: 0,
            page: 1,
            items: [],
            disabledPagination: true,
            dialogDelete: false,
            totalItems: 0,
            options: {},
            debouncedInput: '',
            timeout: null,
            searchTerm: '',
            userInboxQuickResponsesDialog: false,
            userInboxQuickResponses: new UserInboxQuickResponses(),
            user: JSON.parse(localStorage.getItem('user')),
        }
    },
    watch: {
        params: {
            handler () {
                this.getTableData()
            },
            deep: true,
        },
    },
    computed: {
        actions () {
            return [
                { id: 'welcome-message', text: this.$t('Mensaje de bienvenida').toString(), icon: 'fas fa-hand-paper' },
                { id: 'automatic-absent-messages', text: this.$t('Horario de oficina').toString(), icon: 'fas fa-calendar' },
                { id: 'quick-responses', text: this.$t('Respuestas rápidas').toString(), icon: 'fas fa-tachometer-alt-fast' },
                { id: 'triggered-messages', text: this.$t('Respuestas automáticas').toString(), icon: 'fas fa-comments' },
                { id: 'close-reason', text: this.$t('Razones de cierre').toString(), icon: 'fas fa-archive' },
                { id: 'activity-days', text: this.$t('Cierre por inactividad').toString(), icon: 'fas fa-hourglass-half' },
                { id: 'tags', text: this.$t('Etiquetas').toString(), icon: 'fas fa-tags' },
            ]
        },
        headers () {
            return [
                {
                    text: this.$t('Nombre'),
                    value: 'response_short',
                },
                {
                    text: this.$t('Texto'),
                    value: 'response_full',
                },
                {
                    text: this.$t('Fecha de creación'),
                    value: 'created_at',
                },
                {
                    text: this.$t('Acciones'),
                    value: 'actions',
                    sortable: false,
                },
            ]
        },
        searchTermHelper: {
            get: function () {
                return this.debouncedInput
            },
            set: function (val) {
                this.debouncedInput = val
                if (this.timeout !== null) {
                clearTimeout(this.timeout)
                }
                this.timeout = setTimeout(() => {
                this.searchTerm = val
                this.options.page = 1
                }, 700)
            },
        },
        params (nv) {
            return {
                ...this.options,
                query: this.searchTerm,
            }
        },
    },
    mounted () {
        this.selectedComponent = this.actions[0].id
        this.getUserInboxAllowedHours()
        this.getAutoMessages()
        setTimeout(() => {
            this.$refs.absentMessageTextFormatting.setContext(this, this.$refs['message-ref-absent'].$el.querySelector('textarea'))
        })
    },
    methods: {
        addEmojiToMessage (emoji, userInboxAuthomaticMessagesItem, ref) {
            const textarea = this.$refs[ref].$refs.input
            const sentence = textarea.value
            const len = sentence.length
            let pos = textarea.selectionStart
            if (pos === undefined) {
                pos = 0
            }

            const before = sentence.substr(0, pos)
            const after = sentence.substr(pos, len)

            userInboxAuthomaticMessagesItem.message = before + emoji + after

            this.$nextTick().then(() => {
                textarea.selectionStart = textarea.value.length
                textarea.click()
            })
        },
        setTextFormatContext () {
            setTimeout(() => {
                this.$refs.quickResponseTextFormatting.setContext(this, this.$refs.messageInput.$el.querySelector('textarea'))
            })
        },
        createOrUpdateAvailableTimes () {
            this.userInboxAbsentMessage.authomaticMessageType = 2
            if (this.userInboxAbsentMessage.id !== null) {
                this.saveAutoMessages(this.userInboxAbsentMessage)
            } else {
                this.createAutoMessage(this.userInboxAbsentMessage)
            }
            if (this.availableTimes.times.length === 0) {
                EventBus.$emit('AvailableTimes:set24Hours')
            }
            setTimeout(() => {
                this.storeAvailableTimes()
            }, 100)
        },

        createAutoMessage (userInboxAutoMessage) {
            InboxConfigService.createAutoMessages(userInboxAutoMessage)
            .then((response) => {
                EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los mensajes automáticos'))
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar los mensaje automáticos'))
            })
        },

        saveAutoMessages (userInboxAutoMessage) {
            InboxConfigService.saveAutoMessages({ automaticMessages: [userInboxAutoMessage] }).then((response) => {
                EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los mensajes automaticos'))
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar los mensaje automácticos'))
            })
        },

        storeAvailableTimes () {
            if (this.userInboxAllowedHours.id) {
                this.updateAvailableTimes()
            } else {
                this.saveAvailableTimes()
            }
        },
        saveAvailableTimes () {
            this.userInboxAllowedHours.availableTimes = JSON.stringify(this.availableTimes.times)
            InboxConfigService.saveAvailableTimes(this.userInboxAllowedHours).then((response) => {
                this.userInboxAllowedHours.id = response.id
                EventBus.$emit('showAlert', 'success', this.$t('Se han guardado el horario permitido con éxito'))
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar el horario permitido'))
            })
        },
        updateAvailableTimes () {
            this.userInboxAllowedHours.availableTimes = JSON.stringify(this.availableTimes.times)
            this.userInboxAllowedHours.msisdn = this.selectedUserMsisdnConfig
            InboxConfigService.updateAvailableTimes(this.userInboxAllowedHours).then((response) => {
                EventBus.$emit('showAlert', 'success', this.$t('Se han actualizado el horario permitido con éxito'))
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al actualizar el horario permitido'))
            })
        },
        toggleActive (userInboxAbsentMessage) {
            if (this.availableTimes.times.length > 0) {
                userInboxAbsentMessage.active = !userInboxAbsentMessage.active
            }
        },
        getUserInboxAllowedHours () {
            InboxConfigService.getAvailableTimes().then((response) => {
                if (Object.keys(response).length) {
                    this.userInboxAllowedHours.loadData(response)
                    this.availableTimes.times = AvailableTimeModel.parseAvailableTimes(JSON.parse(this.userInboxAllowedHours.availableTimes))
                } else {
                    this.userInboxAllowedHours = new UserInboxAllowedHours()
                    this.availableTimes = {
                        times: [],
                    }
                }
                this.ready = true
            })
        },
        getAutoMessages () {
            InboxConfigService.getAutoMessages().then((response) => {
                this.initUserInboxAbsentMessage(response[1] ?? null)
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al obtener los mensaje automácticos'))
            })
        },

        initUserInboxAbsentMessage (data) {
            if (data !== null) {
                this.userInboxAbsentMessage.loadData(data)
            } else {
                this.userInboxAbsentMessage.reset()
            }
        },
        confirmRemove () {
            InboxConfigService.removeResponse({ id: this.userInboxQuickResponses.id }).then((response) => {
                this.dialogDelete = false
                this.getTableData()
                EventBus.$emit('showAlert', 'success', this.$t('Se ha eliminado la respuesta rápida'))
                EventBus.$emit('WhatsappInbox::quickResponsesChanged')
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al eliminar la respuesta rápida'))
            })
        },
        prepareEditResponse (response) {
            this.userInboxQuickResponses = new UserInboxQuickResponses(response)
            this.userInboxQuickResponsesDialog = true
            this.setTextFormatContext()
        },
        removeResponse (response) {
            this.userInboxQuickResponses = new UserInboxQuickResponses(response)
            this.dialogDelete = true
        },
        getParams () {
            const page = this.items.length === 1 && this.options.page > 1
            ? this.options.page - 1
            : this.options.page

            const params = {
                page: page,
                perPage: this.options.itemsPerPage,
                searchTerm: this.searchTerm,
            }

            for (let i = 0; i < this.options.sortBy.length; i++) {
                params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
                params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
                ? 'asc'
                : 'desc'
            }
            return params
        },
        getTableData () {
            this.disabledPagination = true
            const data = this.getParams()
            this.items = []
            InboxConfigService.getQuickResponsesData(data).then((response) => {
                this.items = response.data
                this.disabledPagination = false
                this.itemsPerPage = parseInt(response.per_page)
                this.page = response.current_page
                this.totalItems = response.total
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al obtener las respuestas rápidas'))
            })
        },
        store () {
            this.userInboxQuickResponses.msisdn = this.selectedUserMsisdnConfig
            if (this.userInboxQuickResponses.id) {
                this.update()
            } else {
                this.save()
            }
        },
        save () {
            InboxConfigService.save(this.userInboxQuickResponses).then((response) => {
                this.userInboxQuickResponses.id = response.id
                this.userInboxQuickResponsesDialog = false
                this.getTableData()
                EventBus.$emit('showAlert', 'success', this.$t('Se han guardado la respuesta con éxito'))
                EventBus.$emit('WhatsappInbox::quickResponsesChanged')
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar la respuesta'))
            })
        },
        update () {
            InboxConfigService.update(this.userInboxQuickResponses).then(() => {
                this.userInboxQuickResponsesDialog = false
                this.getTableData()
                EventBus.$emit('showAlert', 'success', this.$t('Se han actualizado la respuesta con éxito'))
                EventBus.$emit('WhatsappInbox::quickResponsesChanged')
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al actualizar la respuesta'))
            })
        },
        newResponse () {
            this.userInboxQuickResponses = new UserInboxQuickResponses()
            this.userInboxQuickResponsesDialog = true
            this.setTextFormatContext()
        },
    },
}
