import { Service } from '@/services/service'

const PATH = 'rcs/inbox-config/'

const PATH_QUICK_RESPONSES = PATH + 'quick-responses/'

const PATH_AUTO_MESSAGES = PATH + 'auto-messages/'
const PATH_AVAILABLE_TIMES = PATH + 'available-times/'
const PATH_WELCOMING_AGENT = PATH + 'welcoming-agent/'

class InboxConfigService extends Service {
  getQuickResponsesData (params = {}) {
    return this.get(PATH_QUICK_RESPONSES + 'responses', params)
  }

  allResponses (params = {}) {
    return this.get(PATH_QUICK_RESPONSES + 'all-responses', params)
  }

  removeResponse (params = {}) {
    return this.post(PATH_QUICK_RESPONSES + 'remove', params)
  }

  save (params = {}) {
    return this.post(PATH_QUICK_RESPONSES + 'save', params)
  }

  update (params = {}) {
    return this.post(PATH_QUICK_RESPONSES + 'update', params)
  }


  getAutoMessages (params = {}) {
    return this.get(PATH_AUTO_MESSAGES + 'messages', params)
  }

  createAutoMessages (params = {}) {
    return this.post(PATH_AUTO_MESSAGES + 'create-messages', params)
  }

  saveAutoMessages (params = {}) {
    return this.post(PATH_AUTO_MESSAGES + 'save-messages', params)
  }

  getAvailableTimes (params = {}) {
    return this.get(PATH_AVAILABLE_TIMES + 'available-hours', params)
  }

  saveAvailableTimes (params = {}) {
    return this.post(PATH_AVAILABLE_TIMES + 'save', params)
  }

  updateAvailableTimes (params = {}) {
    return this.post(PATH_AVAILABLE_TIMES + 'update', params)
  }

  getWelcomingAgents (params = {}) {
    return this.get(PATH_WELCOMING_AGENT + 'agents', params)
  }

  saveWelcomingAgents (params = {}) {
    return this.post(PATH_WELCOMING_AGENT + 'save', params)
  }
}

export default new InboxConfigService()
