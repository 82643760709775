import InactivityDaysService from '@/services/rcs/inactivity-days.service.js'
import { InactivityDay } from '@/models/whatsapp/InactivityDay.js'
import EventBus from '@/util/EventBus'
import Info from '@/components/Info/Info.vue'

export default {
  name: 'InactivityDay',
  props: { },
  components: { Info },
  data () {
    return {
      showModalCloseReason: false,
      InactivityDay: new InactivityDay(),
      selectedDays: 15,
      inactivityOptions: [
        { text: 7, value: 7 },
        { text: 15, value: 15 },
        { text: 21, value: 21 },
        { text: 30, value: 30 },
      ],
    }
  },
  computed: { },
  watch: { },
  methods: {
    getSelected () {
      InactivityDaysService.selected(this.InactivityDay).then((data) => {
        if (data.id) {
          this.InactivityDay.loadData(data)
        } else {
          this.InactivityDay.reset()
        }
      })
    },
    save () {
      EventBus.$emit('hideAlert')
      InactivityDaysService.edit(this.InactivityDay).then(() => {
        EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los cambios.'))
      })
    },
    emitChangeCloseReasons () {
      // EventBus.$emit('changedInactivityDays')
    },
  },
  created () {
    this.getSelected()
  },
}

