import EmojiPickerInput from '@/components/EmojiPickerInput/EmojiPickerInput.vue'
import TextFormatting from '@/components/TextFormatting/TextFormatting.vue'
import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import InboxConfigService from '@/services/rcs/inbox-config.service.js'
import { UserInboxAuthomaticMessages } from '@/models/whatsapp/UserInboxAuthomaticMessages.js'
import { UserInboxWelcomingAgent } from '@/models/whatsapp/UserInboxWelcomingAgent'
import AiService from '@/services/ai/ai.service'
import { Agent } from '@/classes/chat/agent'
import CountryService from '@/services/country.service'
import EventBus from '@/util/EventBus'
import { Country } from '@/util/Country'

export default {
    name: 'WelcomeMessage',
    components: {
        EmojiPickerInput,
        TextFormatting,
        SelectCountry,
    },
    props: { },
    data () {
        return {
            userInboxWelcomeMessage: new UserInboxAuthomaticMessages(),
            activeUserWelcomingAgent: false,
            countries: [],
            agentCountryIso: {},
            agents: [],
            selectedAgent: null,
            userWelcomingAgents: [],
            user: JSON.parse(localStorage.getItem('user')),
            Country: Country,
        }
    },
    watch: {

    },
    computed: {
        canAddWelcomingAgent () {
            return this.userWelcomingAgents.map((agent) => agent.countryIso).indexOf(this.agentCountryIso) === -1 &&
                this.agentCountryIso &&
                this.selectedAgent
        },
    },
    async mounted () {
        await this.getAutoMessages()
        await this.getCountries()
        await this.getAgents()
        await this.getUserInboxWelcomingAgent()
         setTimeout(() => {
            this.$refs.welcomeMessageTextFormatting.setContext(this, this.$refs['message-ref-welcome'].$el.querySelector('textarea'))
        })
    },
    created () {

    },
    methods: {
        addEmojiToMessage (emoji, userInboxAuthomaticMessagesItem, ref) {
            const textarea = this.$refs[ref].$refs.input
            const sentence = textarea.value
            const len = sentence.length
            let pos = textarea.selectionStart
            if (pos === undefined) {
                pos = 0
            }

            const before = sentence.substr(0, pos)
            const after = sentence.substr(pos, len)

            userInboxAuthomaticMessagesItem.message = before + emoji + after

            this.$nextTick().then(() => {
                textarea.selectionStart = textarea.value.length
                textarea.click()
            })
            },
        agentName (agent) {
            return agent && this.agents.length > 0 ? this.agents.find((a) => a.id === agent.agentId).name : ''
        },
        deleteUserWelcomingAgent (userWelcomingAgent) {
            const index = this.userWelcomingAgents.findIndex((agent) => agent.countryIso === userWelcomingAgent.countryIso)
            this.userWelcomingAgents.splice(index, 1)
            this.countries.filter((country) => country.value === (userWelcomingAgent.countryIso ?? 'world-wireframe'))[0].disabled = false
        },

        addUserWelcomingAgent () {
            const newAgent = new UserInboxWelcomingAgent({
                userId: this.user.id,
                active: this.activeUserWelcomingAgent,
                countryIso: this.agentCountryIso === 'world-wireframe' ? null : this.agentCountryIso,
                agentId: this.selectedAgent.id,
                agentTypeId: this.selectedAgent.typeId,
            })
            this.userWelcomingAgents.push(newAgent)
            this.countries.filter((country) => country.value === this.agentCountryIso)[0].disabled = true
        },
        getAgents () {
            this.assistants = []
            AiService.getAssistantsSimpleList()
            .then(res => {
                res.forEach(agent => {
                const agentObject = new Agent(agent)
                this.agents.push(agentObject)
                })
            })
        },
        getCountries () {
            CountryService.getAllCountries()
            .then(
                (response) => {
                this.countries = [
                    { value: 'world-wireframe', text: this.$t('Todos') },
                    ...response.map((country) => {
                    return {
                        value: country.iso_code_2,
                        text: `${country.name} +(${country.prefix})`,
                    }
                    }),
                ]
                this.agentCountryIso = this.countries[0].value
                },
                () => {},
            )
        },
        changeActive () {
            this.userWelcomingAgents.forEach((agent) => {
                agent.active = this.activeUserWelcomingAgent
            })
        },
        saveUserWelcomeConfig () {
            this.createOrUpdateWelcomeMessage()
            this.saveWelcomingAgents()
        },

        saveWelcomingAgents () {
            InboxConfigService.saveWelcomingAgents({ agents: this.userWelcomingAgents })
            .then(welcomingAgents => {
                this.userWelcomingAgents = []
                welcomingAgents.forEach(agent => {
                const welcomingAgent = new UserInboxWelcomingAgent()
                welcomingAgent.loadData(agent)
                this.userWelcomingAgents.push(welcomingAgent)
                })
            })
        },

        getUserInboxWelcomingAgent () {
            InboxConfigService.getWelcomingAgents()
            .then(agents => {
                this.userWelcomingAgents = []
                agents.forEach(agent => {
                const welcomingAgent = new UserInboxWelcomingAgent()
                welcomingAgent.loadData(agent)
                this.userWelcomingAgents.push(welcomingAgent)
                this.countries.find((country) => country.value === (welcomingAgent.countryIso ?? 'world-wireframe')).disabled = true
                })
                this.activeUserWelcomingAgent = this.userWelcomingAgents[0]?.active
                this.selectedAgent = null
                this.agentCountryIso = null
            })
        },

        createOrUpdateWelcomeMessage () {
            this.userInboxWelcomeMessage.authomaticMessageType = 1
            if (this.userInboxWelcomeMessage.id !== null) {
                this.saveAutoMessages(this.userInboxWelcomeMessage)
            } else {
                this.createAutoMessage(this.userInboxWelcomeMessage)
            }
        },

        createAutoMessage (userInboxAutoMessage) {
            InboxConfigService.createAutoMessages(userInboxAutoMessage)
            .then((response) => {
                EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los mensajes automáticos'))
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar los mensaje automáticos'))
            })
        },

        saveAutoMessages (userInboxAutoMessage) {
            InboxConfigService.saveAutoMessages({ automaticMessages: [userInboxAutoMessage] }).then((response) => {
                EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los mensajes automaticos'))
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar los mensaje automácticos'))
            })
        },

        getAutoMessages () {
            InboxConfigService.getAutoMessages().then((response) => {
                this.initUserInboxWelcomeMessage(response[0] ?? null)
            }, () => {
                EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al obtener los mensaje automácticos'))
            })
        },

        initUserInboxWelcomeMessage (data) {
            if (data !== null) {
                this.userInboxWelcomeMessage.loadData(data)
            } else {
                this.userInboxWelcomeMessage.reset()
            }
        },
    },
}
