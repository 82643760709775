import { Service } from '@/services/service'

const PATH = 'rcs/'
const CHAT_PATH = PATH + 'chat/'


class RcsService extends Service {
  getPrivateRcsConversations (params) {
    return this.get(CHAT_PATH + 'conversations', params)
  }

  getPrivateRcsMessages (params) {
    return this.post(CHAT_PATH + 'messages', params)
  }
}

export default new RcsService()
