
import { Service } from '@/services/service'

const PATH = 'rcs/inbox-config/inactivity-days/'

class InactivityDaysService extends Service {
  edit (params) {
    return this.post(PATH + 'edit', params)
  }

  selected (params) {
    return this.get(PATH + 'selected', params)
  }

  editStatusByCurrentUser (params) {
    return this.post(PATH + 'status', params)
  }
}

export default new InactivityDaysService()
