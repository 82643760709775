import TagService from '@/services/rcs/tag.service.js'
import { Tag } from '@/models/web-chat/Tag.js'

export default {
    name: 'UserInboxTag',
    props: { },
    data () {
        return {
            showModalCloseReason: false,
            items: [],
            itemsPerPage: 5,
            pageCount: 0,
            page: 1,
            total: 0,
            disabledPagination: true,
            options: {},
            searchTerm: '',
            totalItems: 0,
            tag: new Tag(),
            debouncedInput: '',
            timeout: null,
            confirmDialog: false,
            colorPickerModel: '#000000FF',
            tagUse: 0,
            user: this.$store.getters['auth/getUser'],
        }
    },
    computed: {
        headers () {
            return [
                {
                    text: this.$t('Nombre'),
                    value: 'name',
                },
                {
                    text: this.$t('Color'),
                    value: 'color',
                },
                {
                    text: this.$t('Fecha de creación'),
                    value: 'created_at',
                },
                {
                    text: this.$t('Acciones'),
                    value: 'actions',
                    sortable: false,
                },
            ]
        },
        searchTermHelper: {
            get: function () {
                return this.debouncedInput
            },
            set: function (val) {
                this.debouncedInput = val
                if (this.timeout !== null) {
                    clearTimeout(this.timeout)
                }
                this.timeout = setTimeout(() => {
                    this.searchTerm = val
                    this.options.page = 1
                }, 700)
            },
        },
        params (nv) {
            return {
                ...this.options,
                query: this.searchTerm,
            }
        },
    },
    watch: {
        params: {
            handler () {
                this.getTagsData()
            },
            deep: true,
        },
    },
    methods: {
        colorChanged (color) {
            this.tag.color = color.hexa
        },
        deleteTag () {
            TagService.delete(this.tag).then(() => {
                this.tag = new Tag()
                this.confirmDialog = false
                this.getTagsData()
            })
        },
        preRemoveTag (item) {
            this.tag = item
            TagService.countTagUse(this.tag)
                .then((response) => {
                    this.tagUse = response
                    this.confirmDialog = true
                })
        },
        newTag () {
            this.tag = new Tag()
            this.showModalCloseReason = true
            this.colorPickerModel = '#000000FF'
        },
        save () {
            TagService.save(this.tag).then((response) => {
                this.tag = new Tag()
                this.showModalCloseReason = false
                this.getTagsData()
            })
        },
        prepareEdit (item) {
            this.tag = item
            this.showModalCloseReason = true
            this.colorPickerModel = this.tag.color
        },
        getParams () {
            const page = this.items.length === 1 && this.options.page > 1
                ? this.options.page - 1
                : this.options.page

            const params = {
                page: page,
                perPage: this.options.itemsPerPage,
                searchTerm: this.searchTerm,
                msisdn: this.selectedUserMsisdnConfig,
            }

            for (let i = 0; i < this.options.sortBy.length; i++) {
                params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
                params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
                    ? 'asc'
                    : 'desc'
            }
            return params
        },
        getTagsData () {
            const data = this.getParams()
            this.disabledPagination = true
            TagService.getTagsData(data).then((response) => {
                this.items = response.data
                this.itemsPerPage = parseInt(response.per_page)
                this.page = response.current_page
                this.totalItems = response.total
                this.disabledPagination = false
            })
        },
    },
    mounted () {
    },
}

